import React, { ImgHTMLAttributes, useState } from 'react';
import DemoLogo from '../../assets/Relevant_yield_logo_topbar.png';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
	fallback: string,
}

export default function ImageWithFallback({ fallback, src, alt, ...props }: Props) {
	const [imgSrc, setImgSrc] = useState<string>(src ?? '');
	const [hasError, setHasError] = useState(false);

	const onError = () => {
		if (imgSrc !== fallback) {
			setImgSrc(fallback);
		} else {
			setHasError(true); // Prevents infinite error loading
		}
	};

	if (hasError) {
		return <img src={DemoLogo} alt={alt} {...props} />;
	}
	return <img src={imgSrc || fallback} alt={alt} onError={onError} {...props} />;
}
