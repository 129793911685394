import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Credentials from '../Credentials';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';

class CdnEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { model, submit, field } = this.props;
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h2">
								CDN Settings
							</Typography>
							<TextField label="CDN ID" integer {...field('cdnId')} fullWidth margin="normal" />
							<TextField label="CDN Domain" {...field('cdnDomain')} fullWidth margin="normal" />
							<Credentials
								model={model.credentials}
								field={(name) => field(`credentials.${name}`)}
							/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item>
					<ActionButton
						label="Ok"
						color="primary"
						onClick={submit}
					/>
				</Grid>
			</Grid>
		);
	}
}

CdnEditor.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
};

CdnEditor.defaultProps = {
};

export default CdnEditor;
