/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../styles/base.css';
import AdminDashboard from '../pages/AdminDashboard';
import { AdminPublisherList } from '../pages/AdminPublisherList/AdminPublisherList';
import PublisherEdit from '../pages/PublisherEdit';
import AdminList from '../pages/AdminList';
import AdministratorEdit from '../pages/AdministratorEdit';
import GlobalSettingsProgrammatic from '../pages/GlobalSettingsProgrammatic';
import GlobalSettingsAudience from '../pages/GlobalSettingsAudience';
import PricelistEdit from '../pages/PricelistEdit/index';
import AdministratorJobLogsPage from '../pages/AdministratorJobLogs';
import PublisherUserList from '../pages/PublisherUserList';
import UserEdit from '../pages/UserEdit';
import SystemData from '../lib/systemData';
import BuildProvider from '../providers/BuildProvider';
import PrebidBuildsPage from '../pages/PrebidBuilds';
import Page from '../containers/Page';
import { stores } from '../stores';
import {
	AlarmsPage,
	SegmentListPage,
	SegmentEditPage,
	RevenueRulesPage,
	MySegmentsPage,
	AudienceTagFields,
	PlacementTagFields,
	PbConfigTagFields,
	SiteTagFields,
	TagDataEditorPage,
	CdnEditorPage,
	CintEditorPage,
	DocumentTemplatePage,
	DocumentTemplateListPage,
	AdvertiserMappingPage,
	AdvertiserMappingListPage,
	InvoicingPage,
	InvoiceEditorPage,
	PortfolioEditorPage,
	HbManagementPage,
	GlobalAdminPage,
	TriggerWorkspacePage,
} from '../pages/SimplePages';
import EventPage from '../pages/EventPage';
import { ReportTemplatesPage } from '../pages/ReportTemplates/ReportTemplatesPage';
import { reportRoutes } from '../components/Report/utils';
import { PublisherSearchBar } from '../pages/AdminPublisherList/PublisherSearchBar';

function Administrator({ children }) {
	return (
		<BuildProvider>
			<div>
				<Switch>
					<Route
						exact
						path="/dashboard"
						render={(props) => (
							<Page title="Dashboard">
								<AdminDashboard {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/invoicing"
						render={(props) => (
							<Page
								title="Invoices"
							>
								<InvoicingPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/invoicing/:id"
						render={(props) => (
							<Page
								title="Invoices"
							>
								<InvoiceEditorPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/accounts"
						render={(props) => (
							<Page
								title="Publisher Accounts"
							>
								<AdminPublisherList {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/accounts/searchtest"
						render={(props) => (
							<Page
								title="Publisher Accounts"
							>
								<PublisherSearchBar {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path={['/accounts/:id', '/accounts/:id/sites/:siteId', '/accounts/:id/sites/:siteId/placements/:placementId']}
						render={(props) => (
							<Page
								title="Account settings"
							>
								<PublisherEdit {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/accounts/:id/users"
						render={(props) => (
							<Page
								title="Users"
							>
								<PublisherUserList {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/accounts/:id/users/:userId"
						render={(props) => (
							<Page
								title="Edit user"
							>
								<UserEdit {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/administrators"
						render={(props) => (
							<Page
								title="Administrators"
							>
								<AdminList {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/administrators/:id"
						render={(props) => (
							<Page
								title="Edit admin"
							>
								<AdministratorEdit {...props} />
							</Page>
						)}
					/>
					<Route
						path="/settings/audience-configurations"
						render={(props) => (
							<Page
								title="Audience configurations"
							>
								<GlobalSettingsAudience {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/segment-list"
						render={(props) => (
							<Page
								title="Segment list"
							>
								<SegmentListPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/revenue-rules"
						render={(props) => (
							<Page
								title="Revenue rules"
							>
								<RevenueRulesPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/audience-tag-fields"
						render={(props) => (
							<Page
								title="Audience tag fields"
							>
								<AudienceTagFields {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/placement-tag-fields"
						render={(props) => (
							<Page
								title="Placement tag fields"
							>
								<PlacementTagFields {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/site-tag-fields"
						render={(props) => (
							<Page
								title="Site tag fields"
							>
								<SiteTagFields {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/pb-config-tag-fields"
						render={(props) => (
							<Page
								title="Prebid config tag fields"
							>
								<PbConfigTagFields {...props} />
							</Page>
						)}
					/>
					<Route
						path="/settings/tag-data-edit"
						render={(props) => (
							<Page
								title="Tag data"
							>
								<TagDataEditorPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/admin/cdn-edit"
						render={(props) => (
							<Page
								title="CDN"
							>
								<CdnEditorPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/cint-edit"
						render={(props) => (
							<Page
								title="CINT segments"
							>
								<CintEditorPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/portfolio-edit"
						render={(props) => (
							<Page
								title="Sales portfolios"
							>
								<PortfolioEditorPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/my-segments"
						render={(props) => (
							<Page
								title="My segments"
							>
								<MySegmentsPage {...props} />
							</Page>
						)}
					/>
					<Route
						path="/settings/programmatic-configurations"
						render={(props) => (
							<Page
								title="Integrations"
							>
								<GlobalSettingsProgrammatic {...props} />
							</Page>
						)}
					/>
					<Route
						path="/settings/events"
						render={() => (
							<Page
								title="Events"
							>
								<EventPage />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/segments/:id"
						render={(props) => (
							<Page
								title="Edit segment"
							>
								<SegmentEditPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/pricelists/:id"
						render={(props) => (
							<Page
								title="Edit Price list"
							>
								<PricelistEdit {...props} />
							</Page>
						)}
					/>

					{ SystemData.genericData.hasGlobalAdminAccess && (
						<Route
							exact
							path="/admin/instances"
							render={(props) => (
								<Page
									title="Instances"
								>
									<GlobalAdminPage {...props} />
								</Page>
							)}
						/>
					)}
					{ stores.identity.isSuperAdministrator() && (
						<Route
							path="/settings/prebid-builds"
							render={(props) => (
								<Page
									title="Prebid builds"
								>
									<PrebidBuildsPage {...props} />
								</Page>
							)}
						/>
					)}
					{reportRoutes()}
					<Route
						exact
						path="/settings/doc-templates/:id"
						render={(props) => (
							<Page
								title="Document template"
							>
								<DocumentTemplatePage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/doc-templates"
						render={(props) => (
							<Page
								title="Document templates"
							>
								<DocumentTemplateListPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/report-templates"
						render={(props) => (
							<Page
								title="Report and dashboard templates"
							>
								<ReportTemplatesPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/advertiser-mappings"
						render={(props) => (
							<Page
								title="Demand Mappings"
							>
								<AdvertiserMappingListPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/settings/advertiser-mappings/:id"
						render={(props) => (
							<Page
								title="Advertiser mapping"
							>
								<AdvertiserMappingPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/admin/jobs"
						render={(props) => (
							<Page
								title="Jobs"
							>
								<AdministratorJobLogsPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/admin/alarms"
						render={(props) => (
							<Page
								title="System alarms"
							>
								<AlarmsPage {...props} />
							</Page>
						)}
					/>
					<Route
						exact
						path="/alarms"
						render={(props) => (
							<Page
								title="Alarms"
							>
								<TriggerWorkspacePage {...props} />
							</Page>
						)}
					/>

					{ SystemData.genericData.hbmEnabled && (
						<Route
							exact
							path="/hb-management"
							render={(props) => (
								<Page
									title="HB Management"
								>
									<HbManagementPage {...props} />
								</Page>
							)}
						/>
					)}
					{/* Routes available for all */}
					{ children }
					<Redirect to="/dashboard" />
				</Switch>
			</div>
		</BuildProvider>
	);
}

Administrator.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Administrator.defaultProps = {
	children: null,
};

export default Administrator;
