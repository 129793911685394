import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

type ButtonProps = {
	color?: 'primary' | 'secondary';
	variant?: 'contained' | 'outlined' | 'text';
	href?: string;
	icon?: React.ReactNode | true;
	active?: boolean;
	className?: string;
};

export const Button = forwardRef(({
	color = 'primary',
	variant = 'contained',
	href,
	icon,
	active,
	className,
	children,
	...rest
} : ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>, ref: React.Ref<HTMLButtonElement>) => {
	const history = useHistory();
	const isIconButton = icon && !children;

	return (
		<button
			type="button"
			className={clsx(
				// Full style button
				!isIconButton && [
					'flex select-none items-center justify-center gap-2 rounded-[4px] border px-[20px] py-[10px] text-sm leading-6 box-border transition-colors',
					'*:fill-current', // SVG fill to text color
					variant === 'contained' && 'bg-steel-500 text-white border-transparent hover:bg-steel-600 focus:bg-steel-600 active:border-steel-600 active:bg-steel-400 active:text-white disabled:bg-grey-100 disabled:text-grey-450',
					variant === 'outlined' && 'bg-white text-steel-500 border-steel-500 hover:border-steel-600 hover:border-2 hover:px-[19px] hover:py-[9px] hover:text-steel-600 active:bg-steel-400 active:border-steel-600 active:text-white disabled:border-grey-450 disabled:text-grey-450',
					variant === 'text' && 'bg-transparent text-steel-500 border-transparent hover:bg-grey-10-highlight-2 active:border-steel-10',
				],
				// Icon style button
				isIconButton && [
					'inline-flex w-[44px] h-[44px] border border-transparent rounded-full transition-all items-center justify-center',
					'text-grey-500 hover:text-grey-600 hover:bg-cherry-100 active:text-cherry-600 active:border-cherry-600 disabled:text-grey-450',
					'*:fill-current', // SVG fill to text color
					active && '!border-cherry-600 !text-cherry-600',
				],
				className,
			)}
			onClick={href ? (e) => {
				e.preventDefault();
				history.push(href);
			} : undefined }
			{...rest}
			ref={ref}
		>
			{icon}
			{children}
		</button>
	);
});
